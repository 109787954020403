import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ScrollToTop from "./scrollToTop";

// PayPal client IDs
const PAYPAL_CLIENT_IDS = {
  firstHalf:
    "AQpOlwlJJPMSlUjQq4R1gDE9rsFCJ7RchwQyyw-rbAEdzD0v-FxntLbJac6qBpgNNT8-QU-3wo-CPrmS",
  secondHalf:
    "Ad56mFKQ0SGyh79QZaZlPtA9EqsLirrAqEbOYlVoUpP2WjnKgi7qi_rx0gd73n624HBBwtckRIWZf-yV",
};

// Function to determine which client ID to use based on current date
const getPayPalClientId = () => {
  const currentDate = new Date();
  const dayOfMonth = currentDate.getDate();

  return dayOfMonth <= 15
    ? PAYPAL_CLIENT_IDS.firstHalf
    : PAYPAL_CLIENT_IDS.secondHalf;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCC00",
    },
    secondary: {
      main: "#FF4081",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <PayPalScriptProvider
        options={{
          clientId: getPayPalClientId(),
          currency: "GBP",
        }}
      >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PayPalScriptProvider>
    </BrowserRouter>
  </React.StrictMode>
);
